import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import PageNotFound from './components/PageNotFound'
import Spinner from './Spinner'
import TeamPage from './components/team-page/TeamPage'
import GoogleAnalytics from './GoogleAnalytics'

// Config
import { HAS_ANALYTIC_TAGS } from './config'

// Stylesheets
import './common.scss'

// Components
const UploadPage = lazy(() => import('./components/upload/UploadPage'))
const ProjectPage = lazy(() => import('./components/project-view/ProjectPage'))
const ProjectSearchPage = lazy(() => import('./components/project-search/ProjectSearchPage'))

const App = () => {
  return (
    <BrowserRouter>
      {HAS_ANALYTIC_TAGS && <GoogleAnalytics />}
      <Suspense fallback={<Spinner />}>
        <main>
          <Switch>
            <Route exact path="/teams/:teamId/projects" component={TeamPage} />
            <Route exact path="/projects/:projectSlug/upload" component={UploadPage} />
            <Route exact path="/projects/:projectSlug" component={ProjectPage} />
            <Route exact path="/404" component={PageNotFound} />
            <Route exact path="/" component={ProjectSearchPage} />
            {/* Redirect that allows short url like upload.vloggi.com/AUDI-001 to be redirect to the project page */}
            <Route
              path="/:projectSlug"
              render={({ match }) => <Redirect to={`/projects/${match.params.projectSlug}`} />}
            />
          </Switch>
        </main>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
