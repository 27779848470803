// This function add a no-index tag to the main html (only on staging)
const addNoIndexTag = () => {
  const meta = document.createElement('meta')
  meta.setAttribute('name', 'robots')
  meta.setAttribute('content', 'noindex')
  const head = document.getElementsByTagName('head')[0]
  head.insertBefore(meta, head.childNodes[0])
}

// This function customise the OG tags for every project (for URL sharing on social media)
const setPageOGTags = OGTags => {
  const { title, description, url, imageUrl, type } = OGTags
  console.log(title, description, url, imageUrl, type)
  document.querySelector('meta[property="og:title"]').setAttribute('content', title)
  document.querySelector('meta[property="og:description"]').setAttribute('content', description)
  document.querySelector('meta[property="og:url"]').setAttribute('content', url)
  document.querySelector('meta[property="og:image"]').setAttribute('content', imageUrl)
  document.querySelector('meta[property="og:type"]').setAttribute('content', type)
}

const addHotJarTag = () => {
  const head = document.getElementsByTagName('head')[0]
  // const body = document.getElementsByTagName('body')[0]

  // const googleAnalyticScript = document.createElement('script')
  // const googleAnalyticsScipt2 = document.createElement('script')
  // const googleTagManager = document.createElement('script')
  // const googleAnalyticNoScript = document.createElement('noscript')
  const hotjarTag = document.createElement('script')

  // googleAnalyticScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=UA-118075260-7')
  // googleAnalyticScript.setAttribute('async', '')
  // googleAnalyticsScipt2.innerText =
  //   "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'UA-118075260-7');"
  // googleTagManager.innerText =
  //   ";(function(w, d, s, l, i) {;w[l] = w[l] || [];w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });const f = d.getElementsByTagName(s)[0];const j = d.createElement(s);const dl = l != 'dataLayer' ? `&l=${l}` : '';j.async = true;j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;f.parentNode.insertBefore(j, f);})(window, document, 'script', 'dataLayer', 'GTM-TQQM98V');"
  // googleAnalyticNoScript.innerText = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TQQM98V" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`
  hotjarTag.innerText =
    "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:1860558,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"

  // head.insertBefore(googleAnalyticScript, head.childNodes[0])
  // head.insertBefore(googleAnalyticsScipt2, head.childNodes[0])
  // head.insertBefore(googleTagManager, head.childNodes[0])
  head.insertBefore(hotjarTag, head.childNodes[0])
  // body.insertBefore(googleAnalyticNoScript, body.childNodes[0])
}

// Allow to handle line breaks in textArea in order to create HTML list
const convertTextAreaToHTML = text => {
  const lines = text.split('\n')
  const getFirstListItem = line => `<ul><li>${line.replace('-', '')}</li>`
  const getListItem = line => `<li>${line.replace('-', '')}</li>`
  const getLastListItem = line => `<li>${line.replace('-', '')}</li></ul>`
  const getNormalTextLine = line => `<span>${line}</span><br/>`

  return lines
    .map((line, index) => {
      if (line.startsWith('-') && !lines[index - 1]?.startsWith('-')) return getFirstListItem(line)
      if (line.startsWith('-') && !lines[index + 1]?.startsWith('-')) return getLastListItem(line)
      if (line.startsWith('-')) return getListItem(line)
      return getNormalTextLine(line)
    })
    .join('')
}

module.exports = {
  addNoIndexTag,
  setPageOGTags,
  addHotJarTag,
  convertTextAreaToHTML
}
