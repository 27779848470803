import React from 'react'
import { Link } from 'react-router-dom'
import { Header } from 'semantic-ui-react'

const PageNotFound = () => (
  <section
    style={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Header color="red" as="h1">
      404
    </Header>

    <Header color="red" as="h3">
      Oops, page not found...
    </Header>

    <center
      style={{
        fontSize: '20px',
        fontWeight: 300,
        marginTop: '30px',
        textDecoration: 'underline'
      }}
    >
      <Link to="/">Return to project search page</Link>
    </center>
  </section>
)
export default PageNotFound
