import React, { useState, useEffect } from 'react'

// Components
import { Item, Container, Header } from 'semantic-ui-react'

// Utils
import Data from '../../utils/data'

const TeamPage = ({ history, match }) => {
  const [team, setTeam] = useState(null)

  const fetchProjects = async teamId => {
    const { data, error } = await Data.getTeam(teamId)

    if (error) setTeam('notFound')

    setTeam(data)
  }

  // Fetch Data
  useEffect(() => {
    const { teamId } = match.params
    fetchProjects(teamId)
  }, [match.params])

  const goToProjectPage = slug => history.push(`/projects/${slug}`)

  if (!team) return false

  console.log('Rendering projects Page', team)

  const { teamName, projects } = team

  return (
    <Container>
      <Header as="h2">{`${teamName}'s projects`}</Header>
      <Item.Group link divided unstackable>
        {projects.map(project => (
          <Item key={project.id} onClick={() => goToProjectPage(project.slug)}>
            <Item.Image size="small" src={project.imageUrl} />
            <Item.Content>
              <Item.Header as="a">{project.title}</Item.Header>
              <Item.Description>
                <p>{project.description}</p>
              </Item.Description>
            </Item.Content>
          </Item>
        ))}
      </Item.Group>
    </Container>
  )
}

export default TeamPage
