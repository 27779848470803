import axios from 'axios'
import React from 'react'

// Utils
import rollbar from './rollbarErrorLogging'

// Config
import { API_URL } from '../config'

axios.defaults.baseURL = `${API_URL}/rest`
axios.defaults.timeout = 20000 // 20 seconds

// Creation of the resolve object
const resolve = async (action, path, body, headers) => {
  const resolved = {
    data: null,
    error: null
  }

  const request = axios({
    method: action,
    url: path,
    data: body,
    headers: { ...headers }
  })

  try {
    const { data } = await request
    resolved.data = data
  } catch (err) {
    rollbar.error(`Error during api call: ${action} ${path} => ${err?.response.data}`)
    resolved.error = err
  }

  return resolved
}

export default class Data extends React.Component {
  static searchProject = async value => resolve('get', `public/projects/search/?value=${value}`)

  static getProject = async projectSlug => resolve('get', `public/projects/${projectSlug}`)

  static getProjectClips = async projectSlug => resolve('get', `public/projects/${projectSlug}/clips`)

  static getTeam = async teamId => resolve('get', `public/teams/${teamId}`)

  static getClip = async clipId => resolve('get', `public/clips/${clipId}`)

  static getClipPlaceSuggestions = async clipId => resolve('get', `public/clips/${clipId}/placeSuggestions`)

  static updateClip = async (clipId, body) => resolve('put', `public/clips/${clipId}`, body)
}
