/* eslint-disable react/no-unused-state */
import React from 'react'

export const ThemeContext = React.createContext({})

export class ThemeProvider extends React.Component {
  constructor() {
    super()

    this.state = {
      projectTheme: null,
      setProjectTheme: value => this.setState({ projectTheme: value })
    }
  }

  render() {
    console.log('Theme Context State', this.state)

    const { children } = this.props

    return <ThemeContext.Provider value={this.state}>{children}</ThemeContext.Provider>
  }
}
