const environment = process.env.REACT_APP_ENVIRONMENT || 'development'

const config = {
  development: {
    MARKETING_WEBSITE_URL: 'https://www.vloggi.com',
    UPLOAD_TOOL_URL: 'https://upload.staging.vloggi.com',
    S3_BUCKET_URL: 'https://vloggi-assets-development.s3-ap-southeast-2.amazonaws.com',
    API_URL: 'https://api.staging.vloggi.com',
    APP_BUILD: process.env.REACT_APP_BUILD,
    HAS_NO_INDEX_TAG: true, // meta tag preventing bots to index the app
    HAS_ANALYTIC_TAGS: false,
    MAPBOX_API_KEY: process.env.REACT_APP_MAPBOX_API_KEY,
    ROLLBAR_API_KEY: process.env.REACT_APP_ROLLBAR_API_KEY,
    HAS_ROLLBAR_SCRIPT: false
  },

  staging: {
    MARKETING_WEBSITE_URL: 'https://www.vloggi.com',
    UPLOAD_TOOL_URL: 'https://upload.staging.vloggi.com',
    S3_BUCKET_URL: 'https://vloggi-assets-staging.s3-ap-southeast-2.amazonaws.com',
    API_URL: 'https://api.staging.vloggi.com',
    APP_BUILD: process.env.REACT_APP_BUILD,
    HAS_NO_INDEX_TAG: true,
    HAS_ANALYTIC_TAGS: false,
    MAPBOX_API_KEY: process.env.REACT_APP_MAPBOX_API_KEY,
    ROLLBAR_API_KEY: process.env.REACT_APP_ROLLBAR_API_KEY,
    HAS_ROLLBAR_SCRIPT: false
  },

  production: {
    MARKETING_WEBSITE_URL: 'https://www.vloggi.com',
    UPLOAD_TOOL_URL: 'https://upload.vloggi.com',
    S3_BUCKET_URL: 'https://vloggi-assets.s3-ap-southeast-2.amazonaws.com',
    API_URL: 'https://api.vloggi.com',
    APP_BUILD: process.env.REACT_APP_BUILD,
    HAS_NO_INDEX_TAG: false,
    HAS_ANALYTIC_TAGS: true,
    MAPBOX_API_KEY: process.env.REACT_APP_MAPBOX_API_KEY,
    ROLLBAR_API_KEY: process.env.REACT_APP_ROLLBAR_API_KEY,
    HAS_ROLLBAR_SCRIPT: true
  }
}

module.exports = config[environment]
