import React from 'react'
import ReactDOM from 'react-dom'

// Components
import AppWrapper from './AppWrapper'

// Style Sheets
import 'semantic-ui-less/semantic.less'

// Other
import * as serviceWorker from './serviceWorker'

// Config
import { HAS_ANALYTIC_TAGS, HAS_NO_INDEX_TAG } from './config'

// Utils
import { addHotJarTag, addNoIndexTag } from './utils/sharedFunctions'

// Add no-index tag if not in production
if (HAS_NO_INDEX_TAG) addNoIndexTag()

// Add no-index tag if not in production
if (HAS_ANALYTIC_TAGS) addHotJarTag()

ReactDOM.render(<AppWrapper />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
