import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

// Libs
import ReactGA from 'react-ga'

function logPageView(location) {
  // Loggin page view for each history change
  console.log('G.A.: loggin page view', location.pathname + location.search)
  ReactGA.set({ page: location.pathname + location.search })
  ReactGA.pageview(location.pathname + location.search)
}

const GoogleAnalytics = ({ history }) => {
  useEffect(() => {
    // add your UA code
    ReactGA.initialize('UA-118075260-7')

    // Necessary to log the initial page the user lands on
    console.log('G.A.: initial page view', window.location.pathname + window.location.search)
    ReactGA.pageview(window.location.pathname)

    console.log('G.A.: create a new listener')
    const unlisten = history.listen(location => logPageView(location))

    // Remove listener from history
    return () => {
      console.log('G.A.: remove history listener', unlisten)
      unlisten()
    }
  }, [history])

  return true
}

export default withRouter(GoogleAnalytics)
