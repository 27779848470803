/* eslint-disable react/no-unused-state */
import React from 'react'

export const GlobalContext = React.createContext({})

export class GlobalProvider extends React.Component {
  constructor() {
    super()

    this.state = {
      project: null,
      setProject: project => this.setState({ project }),
      loading: false,
      setLoading: value => this.setState({ loading: value }),
      isMobile: false
    }
  }

  componentDidMount() {
    this.setState({ isMobile: window.innerWidth < 1200 }, () =>
      window.addEventListener('resize', this.handleWindowResize)
    )
  }

  handleWindowResize = () => {
    if (window.innerWidth < 1200) this.setState({ isMobile: true })
    if (window.innerWidth >= 1200) this.setState({ isMobile: false })
  }

  render() {
    console.log('Global Context State', this.state)

    const { children } = this.props

    return <GlobalContext.Provider value={this.state}>{children}</GlobalContext.Provider>
  }
}
